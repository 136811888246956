<script>
import { CdrButton, CdrContainer, CdrText } from '@rei/cedar';

import { defineAsyncComponent } from 'vue';
import session from '../../common/session';
import GladlySearch from '../../components/helpCenter/GladlySearch.vue';
import CategoriesComponent from './internalPages/CategoriesComponent.vue';
import internalPagesRouter from './internalPages/router.vue';
import { scrollToTop } from '../../common/utils';
import { sendClickAnalytics } from '../../utils';
import tags from '../../utils/globalTags';
import CardLinksComponent from '../../components/helpCenter/CardLinksComponent.vue';

export default {
  name: 'HelpComponent',
  components: {
    internalPagesRouter,
    GladlySearch,
    HcHomeComponent: defineAsyncComponent(() => import('./internalPages/HcHomeComponent.vue')),
    CUContentComponent: defineAsyncComponent(() => import('../../components/contactUs/CUContentComponent.vue')),
    CUAccordionComponent: defineAsyncComponent(() => import('../../components/contactUs/CUAccordionComponent.vue')),
    CardLinksComponent,
    CategoriesComponent,
    CdrText,
    CdrContainer,
    CdrButton,
  },
  mixins: [session],
  props: {
    title: { type: String, default: 'REI Help Center' },
    gladlyPropertyMap: { type: Object, default() { return {}; } },
    gladlyArticlesRedirect: { type: Object, default() { return {}; } },
    retailStoreHoursEvents: { type: Array, default() { return []; } },
    helpCenterNames: { type: Array, default() { return []; } },
    emailResponseTime: { type: Number, default: 24 },
    helpCenterName: { type: String, default: '' },
    selectedCategory: { type: String, default: '' },
    showContactUsPage: { type: Boolean, default: false },
    showLeftHandNavigation: { type: Boolean, default: false },
    showContactUsAccordion: { type: Boolean, default: false },
    helpCenterNumbers: { type: Object, default() { return {}; } },
    helpCenterCDNResponse: { type: Object, default() { return {}; } },
    gladlyAnswersList: { type: Array, default() { return []; } },
    articlesRedirect: { type: Object, default() { return {}; } },
    showContactUsOnPage: { type: Object, default() { return {}; } },
    showSectionCardLinks: { type: Boolean, default: false },
    cardLinksConfig: { type: Array, default() { return []; } },
    quickTools: { type: Array, default() { return []; } },
    showQuickToolsOnPage: { type: Object, default() { return {}; } },
    relatedLinksConfig: { type: Object, default() { return {}; } },
    articleAnswerId: { type: String, default: null },
    showOrderDetails: { type: Boolean, default: false },
    showPreviousOrderDays: { type: Number, default: 30 },
    statusForOrdersNotShown: { type: Array, default() { return []; } },
    maxNoOfOrders: { type: Number, default: 0 },
    maxCatPopularLinks: { type: Number, default: 0 },
    catPopularLinks: { type: Array, default() { return []; } },
    popularTopicsLinks: { type: Array, default() { return []; } },
    salesHelpLinks: { type: Array, default() { return []; } },
    salesPopularLinks: { type: Array, default() { return []; } },
    reiSalesConfig: { type: Object, default() { return {}; } },
    gladlyNewHelpCenters: { type: Array, default() { return []; } },
    hasSecondaryHC: { type: Boolean, default: false },
    articleRedirectConfig: { type: Array, default() { return []; } },
  },
  data() {
    return {
      accordionsState: false,
      helpCenterMainArray: this.helpCenterNames || [],
      showContactSection: false,
      showContactUsSectionOnPage: true,
      showQuickToolsSectionOnPage: true,
      checkIfArticlePageDisplayed: '',
      helpCenterCDN: null,
      isLanding: '',
      userInfo: null,
      salesConfigValues: null,
    };
  },
  created() {
    const saleValue = this.reiSalesConfig;
    const salesHelpLinks = this.salesHelpLinks?.filter((helpLinks) => helpLinks.saleName === saleValue?.saleName);
    const salesPopularLinks = this.salesPopularLinks?.filter((popularLinks) => popularLinks.saleName === saleValue?.saleName);
    if (salesHelpLinks?.length) {
      saleValue.links = this.groupLinks(salesHelpLinks);
    }
    if (salesPopularLinks?.length) {
      saleValue.popularLinks = this.groupLinks(salesPopularLinks);
    }
    this.salesConfigValues = saleValue;

    this.isLanding = this.checkForLandingSite();
    if ((this.helpCenterCDNResponse?.sections
        && this.helpCenterCDNResponse?.sections?.length > 0)) {
      this.helpCenterCDN = this.helpCenterCDNResponse;
    }
    const { retailStoreHoursEvents } = this;
    this.hoursModel = { retailStoreHoursEvents };
    if (!this.helpCenterName) {
      const results = [];
      while (this.helpCenterMainArray.length) {
        results.push(this.helpCenterMainArray.splice(0, 3));
      }
      this.helpCenterMainArray = results;
      this.getUserInfo();
      this.showContactUsSectionOnPage = this.showContactUsOnPage?.showOnHelpCenterPage;
    } else if (this.selectedCategory) {
      this.showContactUsSectionOnPage = this.showContactUsOnPage?.showOnArticlePage;
      this.showQuickToolsSectionOnPage = this.showQuickToolsOnPage?.showOnArticlePage;
    } else {
      this.showContactUsSectionOnPage = this.showContactUsOnPage?.showOnCategoryPage;
      this.showQuickToolsSectionOnPage = this.showQuickToolsOnPage?.showOnCategoryPage;
    }
  },
  beforeMount() {
    scrollToTop();
  },
  methods: {
    groupLinks(links) {
      const linksArray = [];
      const groupedObjects = links.length && links.reduce((result, obj) => {
        const resultValue = result;
        resultValue[obj.title] = (result[obj.title] || []);
        resultValue[obj.title].push(obj);
        return resultValue;
      }, {});
      const allKeys = (groupedObjects && Object.keys(groupedObjects)) || [];
      if (allKeys.length) {
        allKeys.forEach((key) => {
          const popularLinksObject = {};
          popularLinksObject.title = key;
          popularLinksObject.links = groupedObjects[key].slice(0, 5);
          linksArray.push(popularLinksObject);
        });
      }
      return linksArray;
    },
    async getUserInfo() {
      this.userInfo = await this.getUserAccountData();
      if (this.showOrderDetails) {
        this.showQuickToolsSectionOnPage = !this.userInfo?.isLoggedIn ? this.showQuickToolsOnPage?.showOnHelpCenterPage : false;
      } else {
        this.showQuickToolsSectionOnPage = this.showQuickToolsOnPage?.showOnHelpCenterPage;
      }
    },
    checkForLandingSite() {
      try {
        if (window && typeof window !== 'undefined') {
          const url = new URL(window.location.href);
          return url.searchParams.get('article');
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    redirectToURL(url) {
      window.location = url;
      const clickData = {
        pageName: this.pageName,
        linkName: tags.HELP_CLICK_CONTACT_US,
      };
      sendClickAnalytics(clickData);
    },
    openChatHandler() {
      const { Gladly } = window;

      if (Gladly) {
        Gladly.show();
      }
      const clickData = {
        pageName: this.pageName,
        linkName: tags.HELP_CARD_CHAT,
      };
      sendClickAnalytics(clickData);
    },
    linkMetricHandler(source = 'link') {
      const clickData = {
        pageName: this.pageName,
        linkName: `${tags.HELP}_${source}`,
      };
      sendClickAnalytics(clickData);
    },
    accordionToggle() {
      this.accordionsState = !this.accordionsState;
      const clickData = {
        pageName: this.pageName,
        linkName: `${tags.HELP_CLICK_CONTACT}_${this.accordionsState ? 'open' : 'close'}`,
      };
      sendClickAnalytics(clickData);
    },
    contactUsClick() {
      const clickData = {
        pageName: this.pageName,
        linkName: tags.HELP_CLICK_SHOW_CONTACT_US,
      };
      sendClickAnalytics(clickData);
      if (this.showContactUsPage) {
        this.redirectToURL('/help/contact');
        this.showContactSection = false;
      } else {
        this.showContactSection = true;
      }
      this.checkIfArticlePageDisplayed = this.checkIfArticlePage();
    },
    checkIfArticlePage() {
      const field = 'a';
      const url = window.location.href;
      if (url.indexOf(`?${field}=`) !== -1) {
        return true;
      }
      return url.indexOf(`&${field}=`) !== -1;
    },
  },
};
</script>
<template>
  <div>
    <div
      v-if="isLanding"
      id="help-center-new"
      class="help-center-new"
    >
      <internal-pages-router
        :retail-store-hours-events="retailStoreHoursEvents"
        :email-response-time="emailResponseTime"
        :help-center-numbers="helpCenterNumbers"
        :popular-topics-links="popularTopicsLinks"
      />
    </div>
    <div
      v-else
      id="help-center-new"
      :class="!helpCenterName && 'help-center-new'"
    >
      <div
        v-if="!helpCenterName"
        id="help-center-header"
        class="help-center-header"
        style="display: none"
      >
        <cdr-text
          tag="h1"
          class="help-center-main-heading"
          tabindex="0"
          data-ui="coop-help-center"
        >
          Have a question?
        </cdr-text>
      </div>
      <gladly-search
        v-if="!helpCenterName"
        gladly-id="help-center-gladly-articles"
        gladly-class="help-center-gladly-articles"
        :gladly-property-map="gladlyPropertyMap"
        :show-left-hand-navigation="showLeftHandNavigation"
        :help-center-c-d-n="helpCenterCDN"
        :help-center-names="helpCenterMainArray"
        :related-links-config="relatedLinksConfig"
        :article-answer-id="articleAnswerId"
        :help-center-c-d-n-response="helpCenterCDNResponse"
        :gladly-answers-list="gladlyAnswersList"
        :selected-category="selectedCategory"
        :article-redirect-config="articleRedirectConfig"
        :gladly-new-help-centers="gladlyNewHelpCenters"
      />
      <card-links-component
        v-if="showSectionCardLinks && !helpCenterName"
        :card-links-config="cardLinksConfig"
        :show-quick-tools="showQuickToolsSectionOnPage"
        :quick-tools="quickTools"
        :user-info="userInfo"
        :show-order-details="showOrderDetails"
        :show-previous-order-days="showPreviousOrderDays"
        :status-for-orders-not-shown="statusForOrdersNotShown"
        :max-no-of-orders="maxNoOfOrders"
        :sale-links-config="salesConfigValues"
      />
      <hc-home-component
        v-if="!helpCenterName"
        :help-center-main-array="helpCenterMainArray"
        :gladly-new-help-centers="gladlyNewHelpCenters"
      />
      <categories-component
        v-if="!!helpCenterName"
        :help-center-array="helpCenterMainArray"
        :help-center-name="helpCenterName"
        :selected-category="selectedCategory"
        :gladly-property-map="gladlyPropertyMap"
        :gladly-articles-redirect="gladlyArticlesRedirect"
        :articles-redirect="articlesRedirect"
        :help-center-c-d-n="helpCenterCDN"
        :gladly-answers-list="gladlyAnswersList"
        :show-left-hand-navigation="showLeftHandNavigation"
        :show-quick-tools="showQuickToolsSectionOnPage"
        :quick-tools="quickTools"
        :related-links-config="relatedLinksConfig"
        :cat-popular-links="catPopularLinks"
        :max-cat-popular-links="maxCatPopularLinks"
        :gladly-new-help-centers="gladlyNewHelpCenters"
        :has-secondary-h-c="hasSecondaryHC"
      />
    </div>
    <cdr-container
      v-if="showContactUsSectionOnPage"
      class="help-center"
    >
      <div v-if="showContactUsAccordion && !isLanding">
        <c-u-accordion-component
          :retail-store-hours-events="retailStoreHoursEvents"
          :email-response-time="emailResponseTime"
          :help-center-numbers="helpCenterNumbers"
        />
      </div>
      <div v-if="!showContactUsAccordion && !isLanding">
        <div
          v-if="!showContactSection"
          class="help-center-contact-us"
        >
          <cdr-text
            tag="h2"
            class="help-center__accordion-header"
            data-ui="still-need-more-help"
            tabindex="0"
          >
            Need more help?
          </cdr-text>
          <cdr-button
            modifier="secondary"
            @click="contactUsClick"
          >
            Contact us
          </cdr-button>
        </div>
        <c-u-content-component
          v-if="showContactSection"
          :id="(selectedCategory || checkIfArticlePageDisplayed) ? 'contact-us-home-class': 'contact-us-hc-home-class'"
          :retail-store-hours-events="retailStoreHoursEvents"
          :email-response-time="emailResponseTime"
          :help-center-numbers="helpCenterNumbers"
          :class-name="(selectedCategory || checkIfArticlePageDisplayed || isLanding) ? 'contact-us-home-class': 'contact-us-hc-home-class'"
        />
      </div>
    </cdr-container>
  </div>
</template>
<style lang="scss">
  @import 'src/main/style/helpCenter.scss';
</style>
